import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Video from "../components/video"

import headerVideo from "../videos/about-header-vid.mp4"
import posterImg from "../images/about-poster-img.png"
import descriptionImg1 from "../images/description-img-1.jpg"
import descriptionImg2 from "../images/description-img-2.jpg"
import descriptionImg3 from "../images/description-img-3.jpg"
import descriptionImg4 from "../images/description-img-4.jpg"

import { IconContext } from "react-icons"
import { FaChevronDown } from "react-icons/fa"

import aboutStyles from "../styles/pages/about.module.css"

const AboutPage = () => (
  <Layout location="about">
    <SEO title="About" />
    <div className={ aboutStyles.headerVideoSection }>
      <Video src={ headerVideo } poster={ posterImg } className={ aboutStyles.headerVideo } />
      <div className={ aboutStyles.headerText }>
        <div className={ aboutStyles.headerTextWrapper }>
          <h1>CREATIVITY IS THE KEY.</h1>
          <p>Learn more about the company with the vision.</p>
          <IconContext.Provider value={{ className: aboutStyles.icon }}>
            <FaChevronDown />
          </IconContext.Provider>
        </div>
      </div>
    </div>
    <div className={ aboutStyles.description }>
      <div className={ aboutStyles.descriptionText }>
        <h1>About</h1>
        <p>TrueNorth ArtHouse can create a powerful image for you and your business, all in one stop. With the right graphic design in vivid details or the perfect video, it brings you right to the image you want to project or the message you want to deliver.</p>
        <p>The right concept can do wonders for you and your business. Pictures or images speak a thousand words, but match them with choice words and one gets a piece that delivers it on point to your target market.</p>
      </div>
      <div className={ aboutStyles.descriptionImgs }>
        <div className={ aboutStyles.imgRow }>
          <img src={ descriptionImg1 } />
          <img src={ descriptionImg2 } />
        </div>
        <div className={ aboutStyles.imgRow }>
          <img src={ descriptionImg3 } />
          <img src={ descriptionImg4 } />
        </div>
      </div>
    </div>
  </Layout>
)

export default AboutPage